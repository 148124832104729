import { Link } from 'gatsby';
import styled from 'styled-components';

import { DARK_GRAY, WHITE, YELLOW } from './colors';
import { SIZE, mediaQuery, unit } from './metrics';
import { typeLabel } from './typography';

export const Button = styled.button`
	${typeLabel}
	text-decoration: none;
	outline: none;
	border: none;
	white-space: nowrap;
	background-color: transparent;
	border: 1px solid ${props => props.theme.primary};
	border-radius: 19px;
	line-height: 38px;
	text-align: center;
	height: 38px;
	padding: 0 ${unit(2)};
`;

export const ButtonSquareLink = styled(Link)`
	${typeLabel};
	position: relative;
	padding-top: ${unit(2)};
	padding-bottom: ${unit(2)};
	padding-left: ${unit(3)};
	padding-right: ${unit(3)};
	background-color: ${WHITE};
	color: ${props => props.theme.background_dark};
	text-align: center;
	text-decoration: none;
	overflow: hidden;
	cursor: pointer;
	display: inline-block;

	span {
		position: relative;
		z-index: 2;
	}
`;

export const ButtonLinkOut = styled.a`
	${typeLabel};
	position: relative;
	padding-top: ${unit(2)};
	padding-bottom: ${unit(2)};
	padding-left: ${unit(3)};
	padding-right: ${unit(3)};
	background-color: ${WHITE};
	color: ${props => props.theme.background_dark};
	text-align: center;
	text-decoration: none;
	overflow: hidden;
	cursor: pointer;

	span {
		position: relative;
		z-index: 2;
	}
`;

export const ButtonSquare = styled.div`
	${typeLabel};
	position: relative;
	padding-top: ${unit(2)};
	padding-bottom: ${unit(2)};
	padding-left: ${unit(3)};
	padding-right: ${unit(3)};
	background-color: ${WHITE};
	color: ${props => props.theme.background_dark};
	text-align: center;
	text-decoration: none;
	overflow: hidden;
	cursor: pointer;

	span {
		position: relative;
		z-index: 2;
	}

	${props => props.navbar ? `
		display: none;
		background-color: ${YELLOW};
		color: ${DARK_GRAY};

		&:after {
			background-color: ${WHITE};
		}

		@media screen and (min-width: 940px){
			display: block;
		}
		
		${mediaQuery[SIZE.large]} {
			margin-left: ${unit(3)};
		}
	` : ``}
`;

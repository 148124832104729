import React from 'react';
import { disableScroll, enableScroll } from '../utils/toggleScroll';

const VideoStateContext = React.createContext();
const VideoDispatchContext = React.createContext();

const videoReducer = (state, { action, video = null, videoLink = null }) => {
	switch (action.type) {
		case 'open': {
			disableScroll();
			return { ...state, open: true, video, videoLink };
		}

		case 'close': {
			enableScroll();
			return { ...state, open: false, video, videoLink };
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
};

const VideoProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(videoReducer, {
		open: false,
		video: null,
		videoLink: null,
	});
	return (
		<VideoStateContext.Provider value={state}>
			<VideoDispatchContext.Provider value={dispatch}>
				{children}
			</VideoDispatchContext.Provider>
		</VideoStateContext.Provider>
	);
};

const useVideoState = () => {
	const context = React.useContext(VideoStateContext);
	if (context === undefined) {
		throw new Error('useVideoState must be used within a VideoProvider');
	}
	return context;
};

const useVideoDispatch = () => {
	const context = React.useContext(VideoDispatchContext);
	if (context === undefined) {
		throw new Error('useVideoDispatch must be used within a VideoProvider');
	}
	return context;
};

export { VideoProvider, useVideoDispatch, useVideoState };


import { Link as InternalLink } from "gatsby";
import styled from 'styled-components';

import { DARK_GRAY, PAPER, YELLOW } from '../../../theme/colors';
import { ZINDEX_NAVBAR, unit } from '../../../theme/metrics';
import { typeBodyHeading, typeLabel } from '../../../theme/typography';

export const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: ${DARK_GRAY};
	z-index: ${ZINDEX_NAVBAR};
	opacity: 0;
	visibility: hidden;
	transition: opacity 420ms cubic-bezier(0.22, 1, 0.36, 1), visibility 420ms cubic-bezier(0.22, 1, 0.36, 1), transform 420ms cubic-bezier(0.22, 1, 0.36, 1);
	padding-top: ${unit(8)};
	overflow-y: scroll;

	${props => props.active ? `
		opacity: 1;
		visibility: visible;
	` : ``}

	@media screen and (min-width: 1360px){
		display: none;
	}
`;

export const Inner = styled.div`
	display: flex;
	min-height: 100%;
	
`

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1 1 100%;
	justify-content: center;
	padding-bottom: ${unit(3)};
`;

export const Item = styled.div`
	margin-bottom: ${unit(1)};
	text-align: center;
`;

export const Link = styled(InternalLink)`
	${typeBodyHeading};
	color: ${PAPER};
	text-decoration: none;
`;

export const ReserveButton = styled.div`
	${typeLabel};
	margin-top: ${unit(3)};
	padding-top: ${unit(2.125)};
	padding-bottom: ${unit(2.125)};
	padding-left: ${unit(3)};
	padding-right: ${unit(3)};
	background-color: ${YELLOW};
	cursor: pointer;
`;
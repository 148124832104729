import { DARK_GRAY, GREIGE, PAPER, WHITE, YELLOW } from './colors'

export const THEME_DEFAULT = {
	background: PAPER,
	background_light: PAPER,
	background_dark: DARK_GRAY,
	primary: DARK_GRAY,
	primary_light: GREIGE,
	secondary: YELLOW,
}

export const THEME_DARK = {
	background: DARK_GRAY,
	primary: WHITE,
	secondary: YELLOW,
}

export const THEME_WHITE = {
	background: WHITE,
	primary: DARK_GRAY,
	secondary: YELLOW,
}

export const getTheme = (background) => {
	switch (background.toLowerCase()) {
		case "default":
			return THEME_DEFAULT;
			break;
		case "dark":
			return THEME_DARK;
			break;

	}

	return THEME_DEFAULT;
}
import Color from 'color';
import styled from 'styled-components';

import { GREIGE, WHITE, YELLOW } from '../../../theme/colors';
import { column, grid, row } from '../../../theme/grid';
import { GUTTER, MAX_WIDTH, SIZE, mediaQuery, pixelate, unit } from '../../../theme/metrics';
import { typeBodyRegular, typeFootnote, typeLabelLarge } from '../../../theme/typography';

export const Container = styled.footer`
	${typeBodyRegular};
	display: block;
	width: 100%;
	background-color: ${props => props.theme.background_dark};
	color: ${WHITE};
`

export const FooterContainer = styled.div`
	border-top: 1px solid ${Color(WHITE).alpha(0.2).string()};
`;

export const FooterWrapper = styled.div`
	${grid({ small: true })}
	max-width: ${pixelate(MAX_WIDTH)};
	margin-left: auto;
	margin-right: auto;
`;

export const FooterContent = styled.div`
	${row({ small: true })}
	padding-top: ${unit(9)};

	${mediaQuery[SIZE.medium]} {
		padding-top: ${unit(15)};
	}
`;

export const Block = styled.div`
	${column({
	large: { span: 3 },
	medium: { span: 6 },
	small: { span: 12 },
})};

	padding-bottom: ${unit(5)};

	${mediaQuery[SIZE.large]} {
		padding-bottom: 0;
	}
`;

export const BlockLabel = styled.div`
	${typeLabelLarge};
	margin-bottom: ${unit(2)};
	color: ${WHITE};
`;

export const BlockContent = styled.div`
	color: ${Color(WHITE).alpha(0.7).string()};

	a {
		color: ${Color(WHITE).alpha(0.7).string()};

		&:after {
			transform: scale(0);
		}

		${mediaQuery[SIZE.large]} {
			&:hover{
				&:after {
					transform: scale(1);
				}
			}
		}
	}
`;

export const LinkList = styled.ul`
	li{
		a{
			color: ${Color(WHITE).alpha(0.7).string()};
			text-decoration: none;
			position: relative;
			
			&:after {
				position: absolute;
				content: '';
				left: 0;
				bottom: -2px;
				width: 100%;
				height: 1px;
				background-color: ${YELLOW};
				transform: scale(0);
				transition: transform 280ms ease-out;
			}
		
			${mediaQuery[SIZE.large]} {
				&:hover{
					&:after {
						transform: scale(1);
					}
				}
			}
		}
	}
`;

export const BlockSocial = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${unit(3)};

	a {
		transition: opacity 180ms ease-out;
		padding: 4px 0;

		svg{
			display: block;
		}

		${mediaQuery[SIZE.large]} {
			&:hover {
				opacity: 0.8;
			}
		}
	}
`;

export const Certification = styled.div`
	${column({
	large: { span: 6 },
	medium: { span: 12 },
	small: { span: 12 },
})};

	display: flex;
	row-gap: ${unit(2)};
	padding-top: ${unit(5)};
	flex-direction: column;

	${mediaQuery[SIZE.medium]} {
		flex-direction: row;
		align-items: center;
		column-gap: ${unit(3)};

		svg{
			flex: 0 0 85px;
		}
	}

	${mediaQuery[SIZE.large]} {
		padding-top: ${unit(10)};
	}
`;

export const CertificationText = styled.div`
	${typeFootnote};
	color: ${GREIGE};
	line-height: 1.5;

	a{
		color: ${GREIGE};
		text-decoration: underline;
		transition: opacity 180ms ease-out;

		&:after{
			content: none;
		}

		${mediaQuery[SIZE.large]} {
			&:hover {
				opacity: 0.8;
			}
		}
	}
`;

export const LegalLinkList = styled.ul`
	${typeFootnote};
	color: ${GREIGE};
	text-transform: uppercase;
	display: flex;
	flex-direction: column;
	row-gap: ${unit(3)};

	li{
		a{
			color: ${GREIGE};
			text-decoration: none;
			transition: opacity 180ms ease-out;
	
			${mediaQuery[SIZE.large]} {
				&:hover {
					opacity: 0.8;
				}
			}
			
		}
	}

	${mediaQuery[SIZE.medium]} {
		flex-direction: row;
		column-gap: ${unit(1)};

		li{
			&:after{
				content: '|';
				margin-left: ${unit(1)};
			}

			&:last-child{
				&:after{
					content: none;
				}
			}
		}
	}
`;

export const Copyright = styled.div`
	${typeFootnote};
	color: ${GREIGE};
	text-transform: uppercase;
	margin-right: ${unit(4)};
`;

export const Legal = styled.div`
	display: flex;
	margin-bottom: ${unit(10)};
	flex-direction: column;
	row-gap: ${unit(3)};

	${mediaQuery[SIZE.medium]} {
		flex-direction: row;
		column-gap: ${unit(1)};
		margin-bottom: ${unit(2)};
	}
`;

export const CompanyText = styled.div`
	${typeFootnote};
	color: ${GREIGE};
	line-height: 1.5;
`;

export const Bottom = styled.div`
	max-width: ${pixelate(MAX_WIDTH + (GUTTER.large * 2))};
	margin-left: auto;
	margin-right: auto;
	padding-top: ${unit(10)};
	padding-bottom: ${unit(3)};
	padding-left: ${pixelate(GUTTER.small)};
	padding-right: ${pixelate(GUTTER.small)};

	${mediaQuery[SIZE.medium]} {
		padding-left: ${pixelate(GUTTER.medium)};
		padding-right: ${pixelate(GUTTER.medium)};
	}

	${mediaQuery[SIZE.large]} {
		padding-top: ${unit(15)};
	}
`;




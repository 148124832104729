import Cookies from 'js-cookie';
import React from 'react';

const CookieStateContext = React.createContext();
const CookieDispatchContext = React.createContext();

const cookieConsent = 'cookie-status';

const cookieReducer = (state, action) => {
	switch (action.type) {
		case 'cookieCheck': {
			const accepted = Cookies.get(cookieConsent);
			if (accepted) {
				return { ...state, optIn: true };
			}

			return { ...state, optIn: false };
		}

		case 'cookieAccept': {
			Cookies.set(cookieConsent, true, { expires: 10000 });

			return { ...state, optIn: true };
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
};

const CookieProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(cookieReducer, {
		// default to true to hide cookie banner on first load
		optIn: true,
	});
	return (
		<CookieStateContext.Provider value={state}>
			<CookieDispatchContext.Provider value={dispatch}>
				{children}
			</CookieDispatchContext.Provider>
		</CookieStateContext.Provider>
	);
};

const useCookieState = () => {
	const context = React.useContext(CookieStateContext);
	if (context === undefined) {
		throw new Error('useCookieState must be used within a CookieProvider');
	}
	return context;
};

const useCookieDispatch = () => {
	const context = React.useContext(CookieDispatchContext);
	if (context === undefined) {
		throw new Error('useCookieDispatch must be used within a CookieProvider');
	}
	return context;
};

export { CookieProvider, useCookieDispatch, useCookieState };


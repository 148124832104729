import React from 'react';
import { getPathFromModel } from '../../../utils/link';

import {
	Container, Inner,
	Item, Link, ReserveButton,
	Wrapper,
} from './mobile-menu.styles';

const MobileMenu = ({
	primaryLinks, secondaryLinks, rightLinks, toggleMobileMenu, active, checkIfSameLocation, buttonLabel
}) => {
	const goToForm = () => {
		const formElement = document.querySelector('#contact-form');
		window.scrollTo(0, formElement.offsetTop - 94);
	};

	return (
		<Container active={active}>
			<Inner>
				<Wrapper>
					{primaryLinks.map((link, index) => (
						<Item key={`${link.name}${index}`}>
							<Link activeClassName='active' to={getPathFromModel({ model: link.model, slug: link.slug })} onClick={() => {
								checkIfSameLocation(getPathFromModel({ model: link.model, slug: link.slug }));
							}}>{link.name || link.title}</Link>
						</Item>
					))}
					{secondaryLinks.map((link, index) => (
						<Item key={`${link.name}${index}`}>
							<Link activeClassName='active' to={getPathFromModel({ model: link.model, slug: link.slug })} onClick={() => {
								checkIfSameLocation(getPathFromModel({ model: link.model, slug: link.slug }));
							}}>{link.name || link.title}</Link>
						</Item>
					))}
					{rightLinks.map((link, index) => (
						<Item key={`${link.name}${index}`}>
							<Link activeClassName='active' to={getPathFromModel({ model: link.model, slug: link.slug })} onClick={() => {
								checkIfSameLocation(getPathFromModel({ model: link.model, slug: link.slug }));
							}}>{link.name || link.title}</Link>
						</Item>
					))}
					<ReserveButton onClick={() => {
						goToForm();
						toggleMobileMenu();
					}}>{buttonLabel}</ReserveButton>
				</Wrapper>
			</Inner>
		</Container>
	);
};

export default MobileMenu;
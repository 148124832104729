import { css } from 'styled-components';


export const typeBodyRegular = css`
	font-family: "Gotham";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 1.625;
`;

export const typeBodySmall = css`
	font-family: "Gotham";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
`;

export const typeBodyInitial = css`
	font-family: "Gotham";
	font-style: normal;
	font-weight: 300;
	font-size: 91px;
	line-height: 65px;
`;

export const typeLabel = css`
	font-family: "Gotham";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
`;

export const typeLabelLarge = css`
	font-family: "Gotham";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 14px;
	text-transform: uppercase;
`;

export const typeFootnote = css`
	font-family: "Gotham";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
`;


export const typeHeadline = css`
	font-family: "Archer";
	font-style: normal;
	font-weight: 300;
	font-size: 60px;
	line-height: 1.2;
`;

export const typeHeadlineSmall = css`
	font-family: "Archer";
	font-style: normal;
	font-weight: 300;
	font-size: 60px;
	line-height: 1.2;
`;

export const typeSubHeadline = css`
	font-family: "Archer";
	font-style: normal;
	font-weight: 400;
	font-size: 42px;
	line-height: 1.2;
`;


export const typeHeadingSmall = css`
	font-family: "Archer";
	font-style: normal;
	font-weight: 400;
	font-size: 60px;
	line-height: 1;
`;

export const typeHeadingSmallQuote = css`
	font-family: "Archer";
	font-style: normal;
	font-weight: 300;
	font-size: 60px;
	line-height: 1;
`;

export const typeInput = css`
	font-family: "Gotham";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 1.625;
`;

export const typeBodyHeading = css`
	font-family: "Archer";
	font-style: normal;
	font-weight: 400;
	font-size: 23px;
	line-height: 1.15;
`;

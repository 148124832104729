import { Link as InternalLink } from 'gatsby';
import styled from 'styled-components';

import { DARK_GRAY, WHITE, YELLOW } from '../../../theme/colors';
import { GUTTER, SIZE, ZINDEX_NAVBAR, mediaQuery, pixelate, unit } from '../../../theme/metrics';
import { typeLabel } from '../../../theme/typography';

export const Container = styled.nav`
	position: fixed;
	z-index: ${ZINDEX_NAVBAR};
	top: 0;
	left: 0;
	width: 100%;
	height: ${unit(11.75)};
	display: flex;
	align-items: center;
	justify-content: center;
	${props => props.sticky ? `background-color: ${DARK_GRAY}` : ``};
	transition: background-color 480ms cubic-bezier(0.22, 1, 0.36, 1);
`;

export const Wrapper = styled.div`
	position: relative;
	width: calc(100vw - ${pixelate(GUTTER['small'] * 2)});

	${mediaQuery[SIZE.medium]} {
		width: calc(100vw - ${pixelate(GUTTER['large'] * 2)});
	}

	${mediaQuery[SIZE.xlarge]} {
		width: calc(100vw - ${unit(8)});
	}
`;

export const Content = styled.div`
	display: flex;
	align-items: 	center;
	justify-content: space-between;
`;

export const LeftContainer = styled.div`
	margin-left: auto;

	${mediaQuery[SIZE.medium]} {
		margin-left: 0;
	}
`;

export const LeftContent = styled.div`
	display: none;

	@media screen and (min-width: 1360px){
		display: block;
	}
`;

export const RightContent = styled.div`
	display: flex;
	align-items: center;
`;

export const Dot = styled.circle`
	fill: ${props => props.sticky ? WHITE : props.theme.primary};
	transition: fill 420ms cubic-bezier(0.22, 1, 0.36, 1);
`;

export const DotBackground = styled.circle`
	opacity: 0;
	fill: ${WHITE};
	transition: opacity 420ms cubic-bezier(0.22, 1, 0.36, 1);
`;

export const Dots = styled.svg`
	cursor: pointer;
	fill: ${props => props.sticky ? WHITE : props.theme.primary};
`;

export const Logo = styled.svg`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: opacity 380ms cubic-bezier(0.22, 1, 0.36, 1);

	path {
		fill: ${props => props.theme.primary};
		${props => props.sticky ? `fill: ${WHITE}` : ``};
	}

	${mediaQuery[SIZE.large]} {
		&:hover {
			opacity: 0.8;
		}
	}
`;

export const Items = styled.ul`
	display: none;

	@media screen and (min-width: 1360px){
		display: flex;
	}
`;

export const Item = styled.li`
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-right: ${(unit(2))};
	}
`;

export const LogoLink = styled(InternalLink)``;

export const Link = styled(InternalLink)`
	${typeLabel};
	position: relative;
	color: ${props => props.theme.primary};
	${props => props.sticky ? `color: ${WHITE}` : ``};
	text-decoration: none;
	white-space: nowrap;
	transition: opacity 480ms cubic-bezier(0.22, 1, 0.36, 1);

	&:after {
		position: absolute;
		content: '';
		left: 0;
		bottom: -${unit(1)};
		width: 100%;
		height: 1px;
		background-color: ${YELLOW};
		transform: scale(0);
		transition: transform 280ms ease-out;
	}
	
	&.active{
		&:after {
			transform: scale(1);
		}
	}

	${mediaQuery[SIZE.large]} {
		&:hover{
			opacity: 0.8;

			&:after {
				transform: scale(1);
			}
		}
	}
`;

export const SecondaryMenu = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	margin-top: ${unit(5.25)};
	padding-top: ${unit(2)};
	padding-bottom: ${unit(2)};
	padding-left: ${unit(2)};
	padding-right: ${unit(2)};
	background-color: white;
	opacity: 0;
	visibility: hidden;
	transform: scale(0.92);
	transform-origin: top left;
	transition: opacity 420ms cubic-bezier(0.22, 1, 0.36, 1), visibility 420ms cubic-bezier(0.22, 1, 0.36, 1), transform 420ms cubic-bezier(0.22, 1, 0.36, 1);

	${Items} {
		flex-direction: column;
	}

	${Item} {
		&:not(:last-child) {
			margin-bottom: ${(unit(2))};
		}
	}

	${Link} {
		color: ${DARK_GRAY};

		&:after {
			bottom: -${unit(0.25)};
		}
	}

	${props => props.active ? `
		opacity: 1;
		visibility: visible;
	` : ``}

`;

export const SecondaryWrapper = styled.li`
	position: relative;
	display: flex;
	align-items: center;

	${props => props.active ? `
		${DotBackground} {
			opacity: 1;
			visibility: visible;
			transform: scale(1);
		}

		${Dot} {
			fill: ${DARK_GRAY};
		}
	` : ``}

	&:hover {
		${DotBackground} {
			opacity: 1;
		}

		${Dot} {
			fill: ${DARK_GRAY};
		}
	}
`;

export const MenuIcon = styled.div`
	z-index: ${ZINDEX_NAVBAR + 3};
	position: absolute;
	top: 50%;
	right: 0;
	cursor: pointer;
	transform: translate(0, -50%);

	${props => props.mobileMenu ? `
		padding-top: ${unit(1.5)};
		padding-bottom: ${unit(1.5)};
	` : ``};

	${mediaQuery[SIZE.medium]} {
		right: auto;
		left: 0;
	}

	@media screen and (min-width: 1360px){
		display: none;
	}
`;

export const MenuIconTop = styled.div`
	width: ${unit(3.75)};
	height: ${unit(0.25)};
	${props => props.sticky ? `background-color: ${WHITE}` : `background-color: ${props.theme.primary}`};
`;

export const MenuIconBottom = styled.div`
	width: ${unit(3.75)};
	height: ${unit(0.25)};
	margin-top: ${unit(1.25)};
	${props => props.sticky ? `background-color: ${WHITE}` : `background-color: ${props.theme.primary}`};
`;

export const ContactLink = styled(Link)`
	display: none;
	${props => props.sticky ? `color: ${WHITE}` : ``};
	transition: opacity 480ms cubic-bezier(0.22, 1, 0.36, 1);

	${mediaQuery[SIZE.large]} {
		display: block;

		&:hover {
			opacity: 0.8;
		}
	}
`;

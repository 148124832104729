import Color from 'color';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { WHITE, YELLOW } from './colors';
import { SIZE, mediaQuery, unit } from './metrics';
import { typeBodyRegular, typeHeadline, typeInput, typeLabel } from './typography';

export const GlobalStyle = createGlobalStyle`
	${reset}

	html{
		-webkit-font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
	}

	html {
		box-sizing: border-box;
		background-color: ${props => props.theme.background};
	}

	html, body{
		${typeBodyRegular};
		color: ${props => props.theme.primary};

		a {
			color: ${props => props.theme.primary};
		}
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	a{ 
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	}

	h1 {
		${typeHeadline}
	}

	h2 {
		${typeHeadline}
	}

	input, textarea {
		${typeInput};
		width: 100%;
		/* push the text down a bit to achieve vertical align */
		padding-top: 2px;
		padding-left: ${unit(3)};
		color: ${WHITE};
		background-color: transparent;
		border: 1px solid ${Color(WHITE).alpha(0.3).string()};
		border-radius: 0;
		outline: none;
		transition: border-color 250ms ease-out;

		&::-webkit-input-placeholder {
			color: ${WHITE};
		}

		&:-ms-input-placeholder {
			color: ${WHITE};
		}

		&:-moz-input-placeholder {
			color: ${WHITE};
		}

		&[type="checkbox"]{
			filter: grayscale(100%);
		}

		&::placeholder {
			color: ${WHITE};
		}

		&:focus {
			border-color: ${props => props.theme.secondary};
			outline: none;
		}

		${mediaQuery[SIZE.large]} {
			&:hover:not(:focus) {
				border-color: ${WHITE};
			}
		}
	}

	select {
		border-radius: 0;
		${typeInput};
		height: ${unit(7)};
		padding-top: 2px;
		padding-left: ${unit(3)};
		padding-right: ${unit(3)};
		width: 100%;
		background-color: transparent;
		color: ${WHITE};
		appearance: none;
		background-image:
			linear-gradient(45deg, transparent 50%, gray 50%),
			linear-gradient(135deg, gray 50%, transparent 50%);
		background-position:
			calc(100% - ${unit(3)} - 5px) 50%,
			calc(100% - ${unit(3)}) 50%;
		background-size:
			5px 5px,
			5px 5px;
		background-repeat: no-repeat;

		option{
			color: initial;
		}

		&:focus {
			border-color: ${props => props.theme.secondary};
			outline: none;
		}

		${mediaQuery[SIZE.large]} {
			&:hover:not(:focus) {
				border-color: ${WHITE};
			}
		}
	}

	input {
		height: ${unit(7)};

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&[type=number] {
			-moz-appearance:textfield;
		}
	}

	textarea {
		height: ${unit(22.5)};
		padding-top: ${unit(2)};
		resize: none;
	}

	button {
		${typeLabel};
		margin: 0;
		padding: ${unit(2)};
		background-color: ${WHITE};
		color: ${props => props.theme.background_dark};
		border: none;
		outline: none;
		cursor: pointer;
	}

	p:not(:last-child) {
		margin-bottom: ${unit(2)};
	}

	strong {
		font-weight: 700;
	}

	em {
		font-style: italic;
	}

	p {
		a {
			position: relative;
			text-decoration: none;

			&:after {
				position: absolute;
				content: '';
				left: 0;
				bottom: -${unit(0.25)};
				width: 100%;
				height: 1px;
				background-color: ${YELLOW};
				transition: transform 280ms ease-out;
			}
			
			${mediaQuery[SIZE.large]} {
				&:hover{
					&:after {
						transform: scale(0);
					}
				}
			}
		}
	}
`;

import React, { useRef, useState } from 'react';

import { ButtonBackground, ButtonContainer, ButtonLinkContainer, ButtonLinkOutContainer, IconExternalLink } from './button-link.styles';

const ButtonLink = ({ label, link, customEvent, navbar, iconExternal }) => {
	const bgRef = useRef(null);
	const [animationName, setAnimationName] = useState('');

	const handleMouseOver = () => {
		setAnimationName('MoveIn');
	};

	const handleMouseLeave = () => {
		setAnimationName('MoveOut');
	};

	let buttonComponent = null;

	if (link && link[0] === '/') {
		//  Relative link
		buttonComponent = <ButtonLinkContainer to={link}
			onMouseOver={() => handleMouseOver()}
			onMouseLeave={() => handleMouseLeave()}
		>
			<span>{label}</span>
			<ButtonBackground ref={bgRef} animationName={animationName} />
		</ButtonLinkContainer>
	} else if (link) {
		// External Link
		buttonComponent = <ButtonLinkOutContainer href={link} target="_blank"
			onMouseOver={() => handleMouseOver()}
			onMouseLeave={() => handleMouseLeave()}
		>
			<span>{label}</span>
			<IconExternalLink iconExternal={iconExternal} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M4 12L12 4" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M4 4H12V12" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			</IconExternalLink>
			<ButtonBackground ref={bgRef} animationName={animationName} />
		</ButtonLinkOutContainer>
	} else {
		buttonComponent = <ButtonContainer
			onMouseOver={() => handleMouseOver()}
			onMouseLeave={() => handleMouseLeave()}
			onClick={() => customEvent()}
			navbar={navbar}
		>
			<span>{label}</span>
			<ButtonBackground ref={bgRef} animationName={animationName} navbar={navbar} />
		</ButtonContainer>
	}

	return buttonComponent;
};

export default ButtonLink;

import styled, { keyframes } from 'styled-components';

import { WHITE, YELLOW } from '../../../theme/colors';
import { ButtonLinkOut, ButtonSquare, ButtonSquareLink } from '../../../theme/core';
import { SIZE, mediaQuery, unit } from '../../../theme/metrics';

export const ButtonLinkContainer = styled(ButtonSquareLink)``;

export const ButtonContainer = styled(ButtonSquare)``;

export const ButtonLinkOutContainer = styled(ButtonLinkOut)`
	display: flex;
`;

export const ButtonBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	${props => props.navbar ? `background-color: ${WHITE};` : `background-color: ${YELLOW};`};
	transform: translateY(100%);

	${mediaQuery[SIZE.medium]} {
		animation-duration: 480ms;
		animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
		animation-fill-mode: forwards;
		animation-name: ${props => getAnimation(props.animationName)};
	}
`;

export const IconExternalLink = styled.svg`
	${props => props.iconExternal ? `display: flex;` : `display: none;`};
	position: relative;
	z-index: 2;
	margin-left: ${unit(1.25)};
`;

const MoveIn = keyframes`
	0% { transform: translateY(100%); }
	100% { transform: translateY(0); }
`;

const MoveOut = keyframes`
	0% { transform: translateY(0); }
	100% { transform: translateY(-100%); }
`;

const getAnimation = (animationName) => {
	if (animationName === 'MoveIn') {
		return MoveIn;
	} else if (animationName === 'MoveOut') {
		return MoveOut;
	}
};
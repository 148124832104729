require('whatwg-fetch');

const React = require('react');
const VideoProvider = require('./src/context/video-context').VideoProvider;
const CookieProvider = require('./src/context/cookie-context').CookieProvider;
const { ParallaxProvider } = require('react-scroll-parallax');

require("./static/fonts/fonts.css");

exports.onClientEntry = () => {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
}

exports.wrapPageElement = ({ element, props }) => {
  return <ParallaxProvider>{element}</ParallaxProvider>
}

exports.wrapRootElement = ({ element }) => (
		<CookieProvider>
			<VideoProvider>
				{element}
			</VideoProvider>
		</CookieProvider>
);

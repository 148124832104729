import { useLocation } from '@reach/router';
import React from 'react';
import { ThemeProvider } from "styled-components";

import { GlobalStyle } from '../../../theme/global';
import { THEME_DEFAULT } from '../../../theme/theme';

import { useVideoState } from '../../../context/video-context';

// import { ErrorBoundary } from '../../../services/bugsnag';

import PageError from '../../page-error';

import { Container, Wrapper } from './layout.styles';

import get from 'lodash/get';
import Footer from '../footer';
import Navbar from '../navbar';

import loadable from '@loadable/component';
const VideoOverlay = loadable(() => import('../video-overlay'))

const ErrorScreen = () =>
	<Container>
		<PageError message="Something went wrong" />
	</Container>

const Layout = ({ children }) => {

	const { open, videoId } = useVideoState();

	const location = useLocation();

	let heroImageExist = false;
	if (children.props.data.page && (children.props.data.page.image || children.props.data.page.backgroundVideo)) {
		heroImageExist = true;
	}

	if (children.props.location.pathname.includes('contact')
		|| children.props.location.pathname.includes('legal')
		|| children.props.location.pathname.includes('article')) {
		heroImageExist = true;
	}

	const isLandingPage = get(children, 'props.data.page.model.apiKey') === 'landing';
	const isConfirmationPage = get(children, 'props.data.page.model.apiKey') === 'confirmation';
	const isCareersPage = location.pathname.includes('/careers');

	return (
		<ThemeProvider theme={THEME_DEFAULT}>
			<Wrapper>
				<GlobalStyle />
				{/* <ErrorBoundary FallbackComponent={ErrorScreen}> */}
				{open && <VideoOverlay />}
				<Navbar heroImageExist={heroImageExist} isCompact={isLandingPage} />
				<Container>{children}</Container>
				<Footer hideForm={isLandingPage || isCareersPage || isConfirmationPage} isCompact={isLandingPage} />
				{/* </ErrorBoundary> */}
			</Wrapper>
		</ThemeProvider>
	)
}

export default Layout;
export const enableScroll = () => {
	if (window && window.matchMedia('(min-width: 1000px)').matches) {
		document.documentElement.style.overflowX = 'hidden';
		document.documentElement.style.overflowY = '';
		document.body.style.overflowX = 'visible';
		document.body.style.overflowY = '';
	} else if (window) {
		document.documentElement.style.overflowY = '';
		document.body.style.overflowY = '';
	}
};

export const disableScroll = () => {
	if (window && window.matchMedia('(min-width: 1000px)').matches) {
		document.documentElement.style.overflowX = 'hidden';
		document.documentElement.style.overflowY = 'hidden';
		document.body.style.overflowX = 'hidden';
		document.body.style.overflowY = 'hidden';
	} else if (window) {
		document.documentElement.style.overflowY = 'hidden';
		document.body.style.overflowY = 'hidden';
	}
};
